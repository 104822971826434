import React from 'react';
import Layout from '../layouts/Layout';
import { graphql, useStaticQuery } from 'gatsby';

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	return (
		<Layout
			title={'On the Run | Employment Opportunities - 404 - Not Found'}
			desc={'404 - Not Found'}
		>
			<div
				className={
					'container mx-auto flex min-h-[70vh] flex-col gap-4 py-20'
				}
			>
				<h1 className={'text-center text-4xl'}>
					<span className={'text-9xl'}>404</span> <br /> Page Not
					Found.
				</h1>
				<a
					href="/"
					className={
						'mx-auto rounded-[4px] bg-otr-blue px-[36px] py-[12px] font-helvetica text-[20px] font-bold leading-[28px] text-white shadow hover:text-white '
					}
				>
					Go back
				</a>
			</div>
		</Layout>
	);
};

export default IndexPage;
